// Footer.js
import React from 'react';

const Footer = () => (
  <div className="footer">
    <p>© {new Date().getFullYear()} www.rabinforest.com</p>
  </div>
);

export default Footer;
